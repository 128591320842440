body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    background: #212121;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
}

.small {
    font-size: 14px;
}

/* body::-webkit-scrollbar {
    display: none;
} */

*::-webkit-scrollbar {
    background: rgba(0,0,0,0.25);
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.0);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.3);
    height: 100px;
    border-radius: 10px;
  }

p {
    margin: 0 0 10px 0;
    padding: 0;
}

section {
    float: left;
    width: 100%;
}

.app {
    width: 100vw;
    height: 100vh;
}

.page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
}

a,
a:visited {
    text-decoration: none;
    color: #fff;
}

h2 {
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.hover {
    cursor: pointer;
}

.hide {
    display: none;
}

/* Loader */

#loading, #errorPopup {
    width: 100vw;
    height: 100vh;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: opacity 0.3s ease;
}

#loading img {
    width: 150px;
    left: 50px;
    position: relative;
    height: auto;
}

#loading.on {
    opacity: 1;
    height: 100vh;
}

#errorPopup {
    font-size: 42px;
    flex-direction: column;
}

.errorRow span {
    line-height: 300%;
    padding: 20px;
    cursor: pointer;
}

/* Navigation */

nav {
    width: 96%;
    padding: 0 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 65px;
    position: absolute;
    top: 0;
    z-index: 3;
}

nav a {
    padding: 0 30px 0 0;
}

/* Transitions */

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 350ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 350ms;
}

.fadeLong-enter {
    opacity: 0;
}
.fadeLong-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}
.fadeLong-exit {
    opacity: 1;
}
.fadeLong-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}

.component {
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease;
    position: absolute;
}

.component.on {
    opacity: 1;
    width: 96%;
    height: calc(100% - 65px);
    top: 65px;
    padding: 0 2% 0 2%;

    /* padding: 60px 30px 30px 30px; */
}

/* Page Style */

#sortBtns {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 3;
}

.sortBtn {
    float: left;
    margin: 0 30px 30px 0;
    cursor: pointer;
}

.mainPage {
    padding: 30px 30px 30px 30px;
}

/* .blockPage.mainPage {
    padding: 0px 0px 30px 0px; 
    } */

.block {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px 40px;
    width: 200px;
    height: 150px;
    display: flex;
    margin: 30px;
}

.listItem {
    /* padding: 0 30px 30px 30px; */
    /* margin: 30px; */
    float: left;
    width: calc(100% - 60px);
}

#projectColumn {
    float: left;
    width: 38%;
}

.project {
    padding: 20px;
    border: 1px solid rgba(255, 255, 255.05);
    cursor: pointer;
    float: left;
    width: calc(100% - 40px);
    margin: 0 0 20px 0;
    background: #262928;
    position: relative;
    z-index: 2;
}

.projectLink {
    float: right;
}

.projectBox .stages {
    float: left;
    /* width: 260px; */
    opacity: 1;
    height: 0;
    overflow: hidden;
    /* position: relative; */
    padding: 0 20px 0 20px;
    transform: translateY(-100px);
    z-index: 1;
    width: calc(100% - 40px);
    border: 1px solid rgba(255, 255, 255.05);
    transition: all 0.3s ease;
}

.projectBox.on .stages {
    height: auto;
    opacity: 1;
    padding: 0 20px 20px 20px;
    transform: translateY(-22px);
}

.subheading p {
    float: left;
    margin: 0 20px 0 0;
}

.stage {
    float: Left;
    width: calc(100% - 0px);
    padding: 10px 0px 0 0;
    margin: 0;
    font-size: 13px;
}

.stage svg {
    float: left;
    padding: 10px 0 0 0;
}

.stage rect.fill {
    fill: rgba(255, 255, 255, 0.25);
    stroke: none;
}

.stage rect.outline {
    fill: none;
    stroke-width: 2;
    stroke: rgba(255, 255, 255, 1);
}

/* Timeline */

#timeColumn {
    /* float: right; */
    width: 38%;
    position: fixed;
    right: 20px;
    top: 65px;
    overflow-y: scroll;
}

#timeColumn svg {
    overflow-y: scroll;
    /* float: right; */
}

.timeLine {
}

/* Form Page */

.backBtn {
    padding: 0 0 30px 0;
    float: left;
    width: 100%;
}

.fieldSet {
    width: 14%;
    min-height: 500px;
    padding-right: 6%;
    float: left;
}


.fieldRow {
    margin: 0 0 15px 0;
    float: left;
    width: 100%;
    text-align: left;
    height: auto;
    z-index: 1;
}

.fieldRow.off {
    z-index: -1;
    height: 0;
    overflow: hidden;
}

.full {
    float: left;
    width: 100%;
}

.label {
    float: left;
    width: 100px;
}

.textBox {
    max-width: 50%;
    clear: left;
    margin: 10px 0 0 0;
}

.fieldRow input {
    font-family: "Roboto", sans-serif;
    width: calc(100% - 20px);
    background: rgba(255, 255, 255, 0.75);
    padding: 10px;
    border: 1px solid #fff;
}

.fieldRow input[type="number"] {
    width: 40px;
    background: none;
    color: #fff;
}

.fieldRow input::placeholder {
    color: #444;
}

.heading {
    float: left;
    width: 100%;
    margin: 5px 0 20px 0;
}

.heading .fieldRow input {
    font-size: 24px;
    background: none;
    border: none;
    color: #fff;
    width: 100%;
    padding: 5px 0 5px 0;
    /* border-bottom: 1px solid #fff; */
}


.fieldRow input#sectionTitle {
    font-size: 14px;
    background: none;
    border: none;
    color: #fff;
    width: calc(100% - 10px);
    padding: 5px;
    height: calc(100% - 10px);
    border: 1px solid rgba(255,255,255,0.2);
}

.fieldRow input#sectionTitle::placeholder {
    color: #aaa;
}


.heading .fieldRow input[type="checkbox"],
.fieldRow input[type="checkbox"] {
    width: auto;
}

.heading .fieldRow input::placeholder {
    color: #fff;
}

.heading .fieldRow input:focus::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.switch {
    position: relative;
    display: inline-block;
    z-index: 1;
    vertical-align: top;
    height: 22px;
    width: 50px;
    border-radius: 11px;
    background: #06ac47;
    color: rgba(255,255,255,0.5);
    cursor: pointer;
    transition: all 0.3s ease;
}

.switchLabel {
    position: relative;
    left: 5px;
    top: 4px;
    font-size: 13px;
}

.switch.off {
  background: #D93025;
}

.switch.off .switchLabel {
  position: relative;
  left: 26px;
}

.dot {
    background: #fff;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: -1px;
    top: -1px;
    transform: translateX(27px);
    transition: transform 0.4s, box-shadow 0.4s;
    box-shadow: -4px -4px 8px var(--light-shadow-2, transparent),
        4px 4px 8px var(--shadow, transparent);
}

.switch.off .dot {
  transform: translateX(0px);
}

.addBtn {
    color: #fff;
    z-index: 3;
    background: none;
    border: 1px solid #fff;
    border-radius: 4px;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    font-size: 13px;
}

.addBtn.on {

}


.fieldRow input#fileUpload, input#popUpload {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    float: left;
    overflow: hidden;
}

ul.dropDown, ul.dropDown li ul, ul.dropDown li {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.dropDown li.mediaSelect, ul.dropDown li.animSelect {
    margin-bottom: 5px;
    cursor: pointer;
    /* opacity: 0.5;
    transition: all 0.2s ease; */
}

/* ul.dropDown li.mediaSelect:hover {
    opacity: 1;
} */

ul.mediaDropDown {
    opacity: 1;
    height: auto;
    border: 1px solid #fff;
    transition: border 0.2s ease;
    /* background: rgba(255,255,255,0.05); */
}

ul.mediaDropDown li {
    padding: 0 10px 5px 10px;
}

ul.mediaDropDown.off {
    opacity: 0;
    height: 0;
    overflow: hidden;
    border: 1px solid transparent;
    transition: opacity 0.3s hidden;
}

.dropDownBtn {
    cursor: pointer;
    float: left;
    width: calc(100% - 22px);
    margin: 0px 0 20px 0;
    border: 1px solid rgba(255,255,255,0.2);
    padding: 10px;
}

.dropDown.inputDropDown.off {
    width: calc(85% - 1px);
    padding: 0;
    border: none;
    margin: 0;
}

.dropDown.inputDropDown {
    /* width: calc(85% - 1px); */
    padding: 0;
    margin: 0 0 20px 0;
    position: relative;
    /* top: -2px;
    left: 1px; */
    width: calc(98% - 2px);
    z-index: 1;
    float: left;
    /* box-shadow: 0 0 10px 0px #fff;  */
    background: #555;
    border-radius: 5px;
}

.dropDown.inputDropDown li.animSelect {
    padding: 0;
    margin: 0;
}

.dropDown.inputDropDown li.animSelect span {
    width: calc(100% - 20px);
    float: left;
    padding: 10px;
    border-bottom: 1px solid #999;
}

.dropDown.inputDropDown li.animSelect span:hover {
    background: rgba(255,255,255,0.2)
}

.dropDown.inputDropDown li.animSelect:last-child span{
    border: none;
}

.addMedia {
    cursor: pointer;
    float: left;
    width: 100%;
    margin-top: 10px;
}

:focus {
    outline: none;
}

.btn {
    padding: 15px 20px;
    /* background: #55a3fa; */
    background: #06ac47;
    color: #fff;
    
    /* border: 1px solid #06ac47; */
    border: none;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    transition: background 0.3s ease;
}

.saveRow .btn {
    /* border: 1px solid #06ac47; */
    /* background:#06ac47; */
    /* border: 1px solid #06ac47; */
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);
}

.btn1 {
    padding: 10px 15px;
    width: calc(100% - 30px);
    /* background: #55a3fa; */
    color: #fff;
    border: 1px solid #fff;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin: 10px 0;
    -webkit-font-smoothing: antialiased;
    border-radius: 3px;
    transition: background 0.3s ease;
    font-size: 13px;
}

.btn1:hover {
    background: #55a3fa;
    border: 1px solid #55a3fa;
}

.btn.unsaved {
    background: #55a3fa;
    box-shadow: 0 4px 8px rgba(0,0,0,0.4);
}

.choiceBtn {
    font-size: 14px;
    padding: 10px 15px;
    background: none;
    color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    /* border-radius: 2px; */
    float: left;
    margin: 0 10px 0 0;
}

.choiceBtn.on {
    /* background: rgba(255,255,255,0.5); */
    border: 1px solid rgba(255, 255, 255, 1);
    color: #fff;
}

/* Canvas */

#canvasRow {
    margin: 0 0 0 0;
    position: fixed;
    bottom: 2%;
    left: 2%;
    width: 96%;
}

#canvasDetails {
    position: fixed;
    top: 20px;
    left: 21%;
    /* right: 20px; */
    z-index: 4;
}

.displayName {
    float: right;
    margin: 0 10px 0 0;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}
.displayName.on, .displayName:hover {
  opacity: 1;
}

#canvasPreview {
    border: 1px solid #fff;
    display: block;
    float: left;
    width: 100%;
    height: 200px;
    margin-left: 1px;
}

.canvasSwiper {
    /* border: 1px solid rgba(255, 255, 255, 0.75); */
    height: 120px;
    overflow-y: visible;
}




.canvasFormWrap {
  width: calc(80% - 2px);
  display: flex;
  justify-content: flex-start;
  align-items: center; 
}

.canvasForm {
    float: left;
    width: calc(62% - 2px);
    height: 500px;
    margin: 0 0 0 0;
    border: 1px solid #fff;
    display: block;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
}

.clipPath {
    width: 100%;
    height: 100%;
    clip-path: inset(0 0 0 0);
}

.showFull .clipPath {
    clip-path: inset(-100% -100%);
}

.canvasForm.showFull {
    border: 1px solid rgba(255,255,255,0);
    overflow: visible;
}

.canvasForm.aspectRatio_0 {
    width: 100%;
  }

.canvasForm.aspectRatio_1 {
  width: calc(500px * 1.78);
}

.canvasForm.aspectRatio_2 {
  width: calc(500px * 1.6);
}




.canvasForm.aspectRatio_3 {
  width: calc(500px * 1.333);
}

/* Drag and Drop */

.draggable {
    /* width: 372px;
    height: 248px; */
    /* position: absolute; */
    display: flex;
    cursor: grab;
    width: 100%;
    height: 100%;
   
    z-index: 2;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    /* border: 1px solid white; */
    

}

.react-draggable {
    /* transition: transform 0.3s ease; */
}

.flip-card {
    width: 100%;
    height: 100%;
    perspective: 1000px;
 
}



.draggableImage img {
  transition: all 0.3s ease;
  
  /* object-fit: none;
  object-position: 50% 50%; */
}

/* .draggableInner{} */

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.showSettings .flip-card .flip-card-inner, .showSettings.flip-card .flip-card-inner {
    transform: rotateY(180deg);
    z-index: 10;
    position: relative;
}

.showSettings .flip-card-front {
    z-index: -1;
    /* position: relative; */
}

.showSettings .flip-card-back {
    z-index: 10;
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #bbb; */
    color: black;
}

.flip-card-back {
    background-color: rgb(100,100,100);
    color: white;
    transform: rotateY(180deg);
    text-align: left;
    padding: 20px;
    width: calc(100% - 40px);
    max-height: calc(100% - 40px);
}


.sectionRows {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
}

#downArrow {
    float: right;
    
}

#downArrow path{
    /* transition: transform 0.3s ease; */
}

.saveRow {
  margin: 30px 0 30px 0;
}

.flip-card-corner {
  position: absolute;
  width: 0;
  height: 0;
  /* left: calc(100% - 30px); */
  left: 0;
  top: calc(100% - 30px);
  border-bottom: 30px solid rgba(0,0,0,0.2);
  border-right: 30px solid transparent;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* transition: all 0.3s ease; */
}

.flip-card-corner.close-corner {
  left: 0;
  border-left: none;
  border-right: 30px solid transparent;
}


/* Resizeable */

.react-draggable {
    display: flex;
    /* border: 1px solid transparent; */
    transition: border 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
}

.react-draggable-dragging, .react-draggable.react-draggable-dragging:hover{
    /* border: 1px solid rgba(255,255,255,0.5); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}


.react-draggable.react-draggable-dragging {
    z-index: 10;
    opacity: 0.9;
}

.react-draggable.showSettings, .react-draggable.setOnTop {
    z-index: 10;
    opacity: 1;
}


  .imgDefault {
      width: 100%;
      height: auto;
  }


  .draggableSettings p {
    font-size: 12px;
    width: 100%;
    float: left;
    margin: 0 0 10px 0;
    padding: 0;
  }

  .canvasLabel {
      /* font-weight: bold; */
      font-size: 12px;
      width: 80px;
      float:left;
      width: 100%;
      line-height: 24px;
      opacity: 0.75;
  }

  .draggableSettings input {
      max-width: 60px;
  }

  #addSection {
      height: 100%;
      /* width: 200px; */
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin: 5px 0 0 0;
      float: right;
      cursor: pointer;
  }

  .sectionTitle {
     margin-bottom: -35px;
     position: relative;
     float: left;
     padding: 5px;
     font-size: 13px;
  }

  .backgroundThumb {
     width: 100%;
     height: auto;
      float: left;
      position: relative;
      z-index: 1;
  }

  

  .deleteBtn {
      cursor: pointer;
      font-size: 13px;
      margin: 40px 0 0 0;
      float: right;
      /* width: 50%; */
  }

  .deleteBtn.btn {
    background: #D93025; 
    padding: 10px 10px;
    font-weight: 400; 
  }
 

  .listPage {
    font-size: 14px;
  }

  .row {
      float: left;
      width: 100%;
      margin: 0px 0 10px 0;
      padding: 5px 0 5px 0;
      /* border-top: 1px solid rgba(255,255,255,0.5); */
  }

  .addNarrative {
    cursor: pointer;
    margin: 0 0 20px 0;
  }

  .narrative.row p{
      margin: 0;
      padding: 0;
  }

  /* .narrative.row {
      width: auto;
      padding: 5px 20px 5px 5px;
      margin: 0 0 10px 0;
      clear: left;
      border: 1px solid rgba(255,255,255,0.5);
  } */


  #manifestColumn {
      float: right;
      position: fixed;
      top: 40px;
      right: 0;
      padding: 0 5px 0 5px;
      z-index: 10;
      height: calc(100vh - 400px);
      width: 230px;
      /* height: 100%; */
     
      transition: transform 0.3s ease;
      z-index: 5;
  }


  #manifestColumn.slide {
      transform: translateX(240px);
  }

  #manifestColumn  h4{
      margin: 0 0 0px 0;
      padding: 0;
      overflow-wrap: break-word;
  }

  .stateSettingsRow {
      margin: 0 0 20px 0;
      padding-top: 10px;
      float: left;
      width: 100%;
      border-top: 0.5px solid rgba(255,255,255,0.1);
  }

  .bottomRule {
    border-bottom: 0.5px solid rgba(255,255,255,0.1);
    padding-bottom: 20px;
  }

  #state_row_0.stateSettingsRow {
    border: none;
  }

  .stateSettingsRow input[type="file"] {
      padding-top: 10px;
  }
  .stateSettingsRow .settingsRow {
      margin-top: 10px;
  }

  .fileNameGroup {
    float: left;
    width: 100%; 
    margin: 10px 0 10px 0;
    overflow-wrap: break-word;
  }

  .fileName {
    overflow-wrap: break-word;
    line-height: 100%;
    padding: 0;
    margin: 0 0 0px 0;
    width: 100%;
    float: left;
    font-size: 12px;
    /* color:  */
  }

  .settingsList {
      float: left;
      width: 100%;
      /* display: inline-block; */
      /* height: 100%; */
      /* margin-top: 10px; */
      /* height: auto; */
      /* padding: 0 0 30px 0; */
      opacity: 1;
      height: 620px;
      overflow:hidden;
      overflow-y: scroll;
  }

  /* .settingsList .off {
      opacity: 0;
      height: 0;
      overflow: hidden;
  } */

  #manifestColumn p {
    cursor: pointer;
    float: left;
    width: 100%;
    margin: 0 0 0 0;
  }
  
  .settingsRow {
      float: left;
      width: 100%;
      margin: 0 0 10px 0;
  }

  .settingsRow.disabled {
      opacity: 0.2;
      cursor: default;
  }

  .settingsRow.disabled input {
    cursor: default;
  }

  .settingsRow.halfRow {
      width: 50%;
  }

  .settingsRow.thirdRow {
    width: 30%;
    margin: 0 3% 0 0;
}

.settingsRow.thirdRow input {
    width: 80%;
    margin: 0 5% 0 0;
}

.settingsRow input {
    width: calc(98% - 8px);
    margin: 0 0 0 0;
    position: relative;
    z-index: 2;
    --webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 2px 4px;
    background: #555;
    color: #ddd;
}

.settingsRow input::placeholder {
    color: #ddd;
}

.settingsRow input#fileReplace {
    border: none;
    background: none;
}

.settingsRow input.on {
   border: 1px solid #037fd4;
}

  .settingsRow.halfRow input {
      width: 80%;
      margin: 0 5% 0 0;
  }

  .settingsRow.halfRow.rightRow input,   .settingsRow.halfRow.rightRow .canvasLabel, .settingsRow.halfRow.rightRow .switch{
      margin: 0 0 0 5%;
  }



  #manifestColumn .react-draggable-dragging, #manifestColumn .react-draggable.react-draggable-dragging:hover{
    /* border: 1px solid rgba(255,255,255,0.5); */
    box-shadow: none;
}   
.showSettings, #manifestColumn .react-draggable {
    /* float: left;
      width: 100%; */
}

  .mediaSettings, .mediaSettings.off  {
      opacity: 0;
      height: 0;
      overflow: hidden;
      float: left;
      padding: 0;
      width: 100%;
  }

  /* .mediaSettings div {
      display: block;
  } */

  .mediaSettings.off div {
      display: none;
  }



  .mediaFile {
      /* height: auto; */
      /* float: left; */
      margin: 10px 20% 10px 0;
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.3s ease;
      display: inline-block;
      width: 80%;
      word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    -webkit-hyphenate-character: "";
  }

  .mediaFile:hover {
      opacity: 1;
  }



    .settingsOn .mediaFile  {
        opacity: 0;
        z-index: -1;
        height: 0;
        overflow: hidden;
    }

    .settingsOn .react-draggable {
        /* position: fixed;
        top: 0; */
        transform: translate(0, 30px) !important;
    }

    .settingsOn .showSettings {
        min-width: 250px;
    }

    /* .settingsOn .showSettings .mediaFile{
        opacity: 1;
        z-index: 4;
        height: auto;
        overflow: visible;
        float: left;
        display: block;
    } */

    .settingsOn .mediaSettings.showSettings.on {
        opacity: 1;
        height: 100vh;
        z-index: 10;
        position: fixed;
        top: 0px;
        right: 0;
        padding: 30px 0px 5px 0px;
        overflow: visible;
        background: #2A2A2B;
        /* background: #343837; */
        /* background: #362928; */
        float: left;
        width: calc(100% - 0);
        display: block;
        max-width: 250px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.45);
        /* height: 500px; */
        }
    
    .mediaThumb {
        transition: all .2s ease-in-out;
    }
    
    .inFocus .mediaThumb {
        opacity: 0.7;

    }

    .inFocus .focus.mediaThumb {
        opacity: 1;
        outline: 1px solid #ec008c;
        box-shadow: 0 1px 6px rgba(0,0,0,0.3);
    }

    .mediaThumb.bounce {
        z-index: 10;
        opacity: 1;
        transform: scale(1.02);
        box-shadow: 0 1px 6px rgba(0,0,0,0.1);
    }
    

    .closeFormBtn {
        float: left;
        /* width: 50%; */
        margin-top: 40px;
        cursor: pointer;
    }

    .closeFormBtn.btn {
        padding: 10px 10px;
        font-weight: 400;
    }



    .sectionHeading .sectionLabel {
        width: 100px;
        float: left;
        font-size: 13px;
        padding: 4px 0 0 0;
    }

    .sectionHeading input[type="number"], .sectionHeading input[type="text"] {
        float: left;
        width: 50px;
        font-size: 13px;
        margin: 0 0 0 0;
        background: none;
        border: 1px solid rgba(255,255,255,0.2);
        color: #fff;
        padding: 5px;
    }
    .sectionHeading input[type="number"]::placeholder, .sectionHeading input[type="text"]::placeholder {
        color: #fff;
    }


    .settingsHeading {
        padding: 5px 0 0px 0;
        margin: 25px 0 0px 0;
        float: left;
        width: 90%;
        font-size: 13px;
        color: rgba(255,255,255,0.3);
        border-top: 1px solid rgba(255,255,255,0.2);
    }

    .deleteSection {
        float: left;
        /* padding: 10px 0 0 0; */
        margin: 30px 10px 0px 0;
        width: auto;
        clear: left;
        cursor: pointer;
        /* opacity: 0.5; */
        /* border-top: 1px solid rgba(255,255,255,0.2); */
    }

    .deleteSection.btn {
        background: #D93025;
        padding: 10px 10px;
        font-weight: 400;
    }

    .leftArrow {
        width: auto;
        position: fixed;
        top: 20px;
        left: 15px;
        height: 14px;
        z-index: 3;
        cursor: pointer;
    }

    .leftArrow img{
        width: auto;
        height: 14px;
        margin: 0 5px -2px 0;
    }

    .formPage {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .formPage.ready {
        opacity: 1;
    }

    .details {
        float: left;
        width: 100%;
        margin: 35px 0 0 0;
        padding-top: 10px;
        opacity: 0.3;
        font-size: 12px;
        border-top: 1px solid rgba(255,255,255,0.5);
    }

    .details a {
        width: 100%;
        float: left;
        margin: 0 0 10px 0;
    }

    .existingFile {
        margin: 20px 0;
        font-size: 13px;
        cursor: pointer;
    }

    .existingFileRow {
        cursor: pointer;
    }


    #menuBtn {
        position: fixed;
        right: 8px;
        top: 8px;
        transform: scale(-1,1);
        width: 24px;
        height: auto;
        cursor: pointer;
        z-index: 3;
    }
    #menuBtn svg {
        width: 100%;
        height: auto;
    }

    .deleteX {
        float: right;
        width: 10px;
        height: auto;
    }

    .deleteX img {
        width: 100%;
        height: auto;
    }

    .deleteSectionX {
        position: absolute;
        /* float: right; */
        margin-bottom: -20px;
        top: 20px;
        z-index: 4;
        
    }

    .deleteSectionX img{
        width: 12px;
        height: auto;
        padding: 3px;
        background: rgba(255,255,255,0.25);
    }

    .canvasFrame div {
        width: 100%;
        text-align: right;
    }

    .fullRow {
        flex-direction: column;
    }

    .swiper-container {
        border-left: 1px solid rgba(255,255,255,0.1);
        border-right: 1px solid rgba(255,255,255,0.1);
    }

    .canvasFrame {
        display: block;
        float: left;
        width: 300px;
        height: 120px;
        min-height: auto;
        margin-left:0;
        cursor: pointer;
        transition: background 0.3s ease;
       
    }
    
    .canvasFrame.on .canvasThumbBorder {
      background: rgba(255,255,255,0.2);
    }


    .canvasFrame div.cueLine {
        height: 100px;
        top: 0px;
        left: 0px;
        width: calc(100% - 1px);
        text-align: left;
        border-left: 1px solid #F3C867;
        position: absolute;
        z-index: 3;
    }

    .canvasThumbBorder {
        float: left;
        position: relative;
        top: 0px;
        margin-top: 20px;
        height: 80px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }


    .canvasThumbBorder img{
        width: 100%;
        height: auto;
    }

    .canvasFrame div.cueLineTitle {
        font-size: 13px;
        padding: 0 0 5px 5px;
        color: #F3C867;
        width: 100%;
        text-align: left;
    }

    .lockIcon {
        margin: 20px 0 0 0;
        cursor: pointer;
    }

    .lockIcon img{
        width: 16px;
        height: auto;
    }
/* 
    .valueInput {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }

    .userInput {
        border: 1px solid rgba(255,255,255,0.2);
        width: calc(100% - 10px);
        padding: 5px;
        float: left;
    } */


    .sectionHeading {
        margin: 0 0 20px 0;
        height: 155px;
    }

    #menuBtn.closeBtn {
    /* right: 8px;
    top: 20px; */
    /* position: relative */
    z-index: 11;
    }

    #menuBtn.closeBtn img{
        width: 15px;
        height: auto;

    }

    .swiper-slide {
        opacity: 0.8;
    }

    .swiper-slide.on {
        opacity: 1;
    }


    .settingsGroup {
        border-bottom: 1px solid rgba(255,255,255,0.2);
        display: inline-block;
        padding: 0px 20px 10px 20px;
        margin: 0 0 10px 0;
        width: calc(100% - 40px);
    }

    .settingsGroup.stateGroup {
        padding-bottom: 20px;
    }

    .settingsGroup.off, .settingsRow.off {
        display: none;
    }
   

    #saveRow {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 210px;
    }
    
    .groupLabel {
        /* width: 100%;
        float: left; */
        width: 100%;
        display: inline-block;
        margin: 0 0 10px 0;
    }

    #manifestColumn  .groupLabel p{
        color: rgba(255,255,255,0.3);
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        cursor: auto;
    }

    #animDropdown ul.on {
        padding-bottom: 10px;
        margin-bottom: 20px;
        
    }
    #animDropdown .dropDownBtn, ul.dropDown li.animSelect {
        font-size: 12px;
        text-transform: capitalize;
    }

    .mediaSettingsInner {
        float: left;
        width: 100%;
        height: calc(100vh - 110px);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .animBtn {
        float: left;
        margin: 0 6px 10px 0;
        padding: 5.25px;
        border-radius: 3px;
        border: 1px solid rgba(255,255,255,0.3);
        font-size: 12px;
        cursor: pointer;
        transition: all 0.3s ease;

    }

    .animBtn.on, .animBtn:hover, .dropDownValue {
        background: rgba(0,0,0,0.1);
        color: #55a3fa;
    }

    .clearValue {
        float: right;
        width: 10px;
        position: relative;
        top: -4px;
        font-size: 18px;
        cursor: pointer;
    }

    .animLabel {
        font-size: 12px;
        text-transform: capitalize;
        margin: 0 0 10px 0;
        width: 100%;
        display: inline-block;
    }

    .animRow.off {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }

    .animObj {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }

    .newAnimObj {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease;
    }

    .newAnimObj.on {
        height: auto;
        opacity: 1;
    }

    .newAnimBtn {
        width: calc(100% - 16px);
        text-transform: capitalize;
    }

    .clearValue:hover {
        color: #D93025;
    }

    .icon {
        width: 16px;
        height: auto;
        padding: 0 0 0 10px;
    }

    .preview {
        transition: all 0.3s ease;
    }

    .preview:hover {
        opacity: 0.8;
    }

    .hover {
        cursor: pointer;
        float: left;
        width: 100%;
    }

    #fileReplace {
        padding: 10px 0 0 0;
    }